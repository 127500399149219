<template>
  <div>
    <v-btn
      @click="dialog = true"
      v-if="errorLog.length > 0"
      color="secondary"
      class="ma-2 pa-3  text-capitalize"
    >
      <v-icon small class="me-1"> mdi-file-document-multiple</v-icon>Error Log
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text">Error Log</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-layout>
              <ul class="mt-5 black--text" v-if="errorLog.length > 0">
                <li v-for="item in errorLog" :key="item">{{ item }}</li>
              </ul>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      snackbar: false,
      text: "",
      valid: true,
    };
  },
  props: {
    errorLog: {
      required: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>