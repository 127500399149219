import { jsPDF } from "jspdf";
import moment from "moment";

import { ARABIC_FONT } from "../../utils/utils";
var pageWidth = 210;
var pageHeight = 297;

var start = 10;
var end = 200;

var normalFontSize = 16;
var mediumFontSize = 14;
var smallFontSize = 10;

var lineHeight = Math.floor(normalFontSize / 2);
var currentY = 2 * lineHeight - 10;
// var xCenter = Math.floor(pageWidth / 2);

let doc = new jsPDF("p", "mm", [pageWidth, pageHeight]);

const printInvoice = (invoice, type) => {


  let addressEn = [
    "Planet Zero Resturant",
    "South Doha - KSA",
    "C.R. 2052101250",
    // "VAT No. 310206914300003"
  ];

  let addressAr = [
    "مطعم كوكب الصفر لتقديم الوجبات ",
    "الدوحة الجنوبية - المملكة العربية السعودية",
    "س.ت. 2052101250",
    // "الرقم الضريبي 310206914300003"
  ];

  doc = new jsPDF("p", "mm", [pageWidth, pageHeight]);
  doc.addFileToVFS('Amiri-Regular-normal.ttf', ARABIC_FONT);
  doc.addFont('Amiri-Regular-normal.ttf', 'Amiri-Regular', 'normal');

  doc.setFontSize(smallFontSize);
  addressEn.map((item, index) => {
    doc.text(item, start, currentY + (lineHeight - 2) * index);
  });
  setArabicFont();
  doc.setFontSize(smallFontSize);
  doc.text(addressAr[0], end + 5, currentY, { align: "right" })
  doc.text(addressAr[1], end + 5, currentY + 6, { align: "right" })
  doc.text(addressAr[2], end, currentY + 12, { align: "right" })
  // doc.text(addressAr[3], end + 5, currentY + 18, { align: "right" })
  fontType("medium");
  doc.addImage("img/logo.png", "jpeg", 100, currentY - 7.8, 30, 30);
  nextLine(3);
  seperator();
  nextLine(1);

  doc.text(`Date :${moment().format('YYYY/MMMM/DD')} `, start, currentY);
  setArabicFont()
  moment.locale('ar_SA');
  doc.text(`التاريخ : ${moment().format('YYYY/MMMM/DD')}`, end, currentY, { align: "right" });
  fontType('medium')
  nextLine(2);
  fontSize(normalFontSize);
  doc.text(`Tax Invoice No : ${invoice.invoiceNo}`, start, currentY);
  setArabicFont();
  doc.text(` ${invoice.invoiceNo}:فاتورة ضريبية رقم `, end, currentY, {
    align: "right"
  });
  fontType("medium");
  nextLine(1);
  //doc.addImage(invoice.qrCode, "png", start + 150, currentY, 30, 30);
  nextLine(2);
  fontSize(mediumFontSize);
  if (type === "invoice") {
    doc.text("Order Summery", start, currentY);
    nextLine(1);
    doc.text("Order placed at : ", start, currentY);
  }
  if (type === "refund") {
    doc.text("Refund Summery", start, currentY);
    nextLine(1);
    doc.text("Refund placed at : ", start, currentY);
  }

  moment.locale('en');
  doc.text(
    moment(new Date(parseInt(invoice.createdAt))).format(
      "DD MMM YYYY, h:mm:ss A"
    ),
    start + 40,
    currentY
  );
  nextLine(1);
  if (type === "invoice") {
    doc.text(`Plan name:${invoice.note} `, start, currentY);
  }
  if (type === "refund") {
    doc.text(`Note :${invoice.note} `, start, currentY);
  }

  fontSize(mediumFontSize);
  nextLine(2);

  nextLine(1.5);
  if (type == "invoice") doc.text("Delivery to ", start, currentY);
  if (type === "refund") doc.text("refund to", start, currentY);
  nextLine(1);
  fontType("bold");
  doc.text(
    `${invoice.customerInfo.firstnameEn} ${invoice.customerInfo.lastnameEn}`,
    start,
    currentY
  );
  nextLine(1);
  fontType("normal");

  let addresses = getFullAddress(invoice);
  setArabicFont();
  addresses.forEach(address => {
    doc.text(address, start, currentY);
    nextLine(1);
  });
  fontType("medium");
  nextLine(1);

  doc.setFillColor(189, 189, 189);
  doc.rect(start, currentY, end - start, 10, "F");
  nextLine(0.7);
  doc.setTextColor(255, 255, 255);
  // fontType("bold");
  setArabicFont();
  doc.setFontSize(smallFontSize);

  doc.text("Item Name /وصف المنتج", start + 5, currentY);
  doc.text("Quantity / الكمية", start + 45, currentY);
  doc.text("Price / السعر", start + 75, currentY);
  doc.text("Tax / الضريبة", start + 100, currentY);
  doc.text("Tax Value / قيمة الضريبة ", start + 125, currentY);
  doc.text("Total  / الاجمالي", start + 165, currentY);
  nextLine(1.5);
  doc.setTextColor(0, 0, 0);
  fontType("medium");

  doc.text(`${invoice.note}`, start + 5, currentY);
  doc.text("1", start + 45, currentY);
  doc.text(`${invoice.amount}`, start + 75, currentY);
  let percentage = 0;
  if (invoice.tax > 0)
    percentage = invoice.tax / (invoice.amount - invoice.discount) * 100
  doc.text(String(percentage.toFixed(1)) + '%', start + 100, currentY);
  doc.text(`${invoice.tax} SR`, start + 125, currentY);
  doc.text(`${invoice.total.toFixed(2)} SR`, start + 165, currentY);
  nextLine(1);
  seperator();
  nextLine(1);

  setArabicFont();
  doc.text("Total / المجموع", end - 50, currentY, { align: "right" });
  fontType("medium");
  doc.text(`${invoice.amount.toFixed(2)} SR`, end - start, currentY, {
    align: "right"
  });
  nextLine(1.5);
  if (type == "invoice") {
    setArabicFont();
    doc.text("Discount / الخصم", end - 50, currentY, { align: "right" });
    fontType("medium");
    doc.text(`${invoice.discount.toFixed(2)} SR`, end - start, currentY, {
      align: "right"
    });
    nextLine(1.5);
  }
  setArabicFont();
  // doc.text("VAT / ضريبة القيمة المضافة", end - 50, currentY, {
  //   align: "right"
  // });
  fontType("medium");
  doc.text(`${invoice.tax.toFixed(2)} SR`, end - start, currentY, {
    align: "right"
  });
  nextLine(1.5);
  doc.setFillColor(222, 222, 222);
  doc.rect(start, currentY, end - start, 10, "F");
  nextLine(0.7);
  fontType("bold");
  if (type == "invoice") {
    fontType("medium");
    setArabicFont();
    doc.text("Total Invoice / اجمالي الفاتورة", end - 50, currentY, {
      align: "right"
    });
  }
  if (type == "refund")
    doc.text("Refund Total: ", end - 50, currentY, { align: "right" });
  fontType("medium")
  doc.text(`${invoice.total.toFixed(2)} SR`, end - start, currentY, {
    align: "right"
  });
  fontType("bold");
  doc.line(0, pageHeight - 20, end, pageHeight - 20);
  fontType("normal");
  //design ends
  currentY = 2 * lineHeight - 10;
  if (type == "invoice") doc.save(`Invoice_${invoice.invoiceNo}.pdf`);
  if (type == "refund") doc.save(`Refund_${invoice.invoiceNo}.pdf`);
};

const fontType = type => doc.setFont(undefined, type);
const fontSize = size => doc.setFontSize(size);
const nextLine = numberOfLines => (currentY += numberOfLines * lineHeight + 1);
const setArabicFont = () => doc.setFont('Amiri-Regular'); // set font


const seperator = item => {
  console.log(item);
  doc.setDrawColor(210, 210, 210);
  doc.line(start, currentY, end, currentY);
};
const getFullAddress = invoice => {
  let fullAddress = invoice.customerInfo.addressLine1.split(",");
  if (fullAddress.length > 3) {
    let addressLine1 = fullAddress[0];
    let addressLine2 = fullAddress[2];
    fullAddress = [addressLine1, addressLine2];
    if (fullAddress.length > 4) {
      fullAddress = [addressLine1, addressLine2];
    }
  }
  return fullAddress;
};

export { printInvoice };
